import { createAction, handleActions } from "redux-actions"
import produce from "immer"

export const RESET_PUSH = "push/RESET_PUSH"
export const SEND_PUSH_REQUEST = "push/SEND_PUSH_REQUEST"
export const SEND_PUSH_SUCCESS = "push/SEND_PUSH_SUCCESS"
export const SEND_PUSH_FAILURE = "push/SEND_PUSH_FAILURE"

export const sendPush = createAction(SEND_PUSH_REQUEST)
export const resetPush = createAction(RESET_PUSH)

const initialState = {
  isLoading: false,
  isLoaded: false,
  isEmpty: true,
  type: "basic",
  status: "",
}

export const pushReducer = handleActions(
  {
    [RESET_PUSH]: state => {
      return produce(state, draft => {
        draft.isLoading = false
        draft.isLoaded = false
        draft.isEmpty = true
        draft.type = "basic"
        draft.status = ""
      })
    },
    [SEND_PUSH_REQUEST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.isLoading = true
        draft.isEmpty = true
        draft.type = payload.type
      })
    },
    [SEND_PUSH_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.isLoading = false
        draft.isEmpty = false
        draft.status = "success"
      })
    },
    [SEND_PUSH_FAILURE]: (state, { payload }) => {
      window.Sentry.captureException(payload)
      return produce(state, draft => {
        draft.isLoading = false
        draft.isEmpty = false
        draft.status = "failure"
      })
    },
  },
  initialState
)
