import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist"
import { composeWithDevTools } from "redux-devtools-extension"
import firebase from "gatsby-plugin-firebase"
import { createFirestoreInstance } from "redux-firestore"
import storageSession from "redux-persist/lib/storage/session"
import ReduxSagaFirebase from "redux-saga-firebase"
import rootReducer from "./rootReducer"
import rootSaga from "./sagas"

export const rsf = new ReduxSagaFirebase(firebase)
rsf.region = "us-central1"
const sagaMiddleware = createSagaMiddleware(rootSaga)
const middlewares = [sagaMiddleware]
const composedEnhancers =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares))
// const composedEnhancers = composeWithDevTools(applyMiddleware(...middlewares))

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store = createStore(persistedReducer, undefined, composedEnhancers)
export let persistor = persistStore(store)
export const rrfProps = {
  firebase,
  config: {
    userProfile: "user_list",
    useFirestoreForProfile: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

sagaMiddleware.run(rootSaga)
