import { combineReducers } from "redux"
import { firebaseReducer as firebase } from "react-redux-firebase"
import { firestoreReducer } from "redux-firestore"
import { persistReducer } from "redux-persist"
import storageSession from "redux-persist/lib/storage/session"
import hardSet from "redux-persist/lib/stateReconciler/hardSet"
import { modalReducer } from "../modal/redux"
import { dialogReducer } from "../dialog/redux"
import { designReducer } from "../design/redux"
import { shopifyReducer } from "../shopify/redux"
import { authReducer } from "../auth/redux"
import { pushReducer } from "../push/redux"

export default combineReducers({
  // firebase: firebaseReducer,
  // firebase: persistReducer(
  //   { key: "firebaseState", storage: storageSession },
  //   firebase
  // ),
  firebase,
  firestore: firestoreReducer,
  push: pushReducer,
  auth: authReducer,
  modal: modalReducer,
  dialog: dialogReducer,
  design: designReducer,
  shopify: shopifyReducer,
})
