import { takeEvery, put, call, all, select } from "redux-saga/effects"
import {
  SEND_PUSH_REQUEST,
  SEND_PUSH_SUCCESS,
  SEND_PUSH_FAILURE,
} from "./redux"
import { getFirebase } from "react-redux-firebase"
import { rsf } from "../redux"
import { navigate } from "gatsby"
import { get } from "lodash"
import axios from "axios"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { track } from "services/analytics"

const FUNCTION_URL = process.env.GATSBY_FIREBASE_FUNCTIONS_URL

// export function* addScheduledPush(shopifyUrl, data) {
//   const firebase = getFirebase()
//   const payload = {
//     ...data,
//     type:
//   }
// }

export function* addPushHistory(shopifyUrl, data) {
  const firebase = getFirebase()
  const DB = firebase.firestore()

  const payload = {
    ...data,
    time: firebase.firestore.FieldValue.serverTimestamp(),
    updated_date: firebase.firestore.FieldValue.serverTimestamp(),
    created_date: firebase.firestore.FieldValue.serverTimestamp(),
    sent: true,
    valid: true,
  }

  const batch = DB.batch()

  const userPushRef = DB.collection("user_list")
    .doc(shopifyUrl)
    .collection("push")
    .doc()
  const historyRef = DB.collection(shopifyUrl)
    .doc("push")
    .collection("history")
    .doc(userPushRef.id)

  batch.set(userPushRef, payload)
  batch.set(historyRef, payload)

  const result = yield batch.commit()

  return result
  // const scheduledPushRef = DB.collection("scheduled_push").doc(id)

  // const result = yield call(
  //   rsf.firestore.addDocument,
  //   `${shopifyUrl}/push/history`,
  //   payload
  // )
  // console.log(result)
  // return result
}

export function* callWhereToConnectImagePush(
  topic,
  title,
  message,
  targetType,
  targetId,
  images
) {
  const token = yield select(
    state => state.firebase.auth.stsTokenManager.accessToken
  )
  const result = yield axios({
    method: "post",
    url: `${FUNCTION_URL}whereToConnectImagePush`,
    data: {
      topic,
      title: title || "",
      message,
      images: images,
      target_id: targetId,
      target_type: targetType,
      type: "image",
    },
    headers: { Authorization: `Bearer ${token}` },
  })

  track("SEND_IMAGE_PUSH")

  return result.data
}
export function* callWhereToConnectPush(shopUrl, title, body) {
  const result = yield call(
    rsf.functions.call,
    `${FUNCTION_URL}whereToConnectPush`,
    {
      shopUrl,
      title: encodeURIComponent(title) || "",
      body: encodeURIComponent(body),
    }
  )

  track("SEND_PUSH")

  return result
}

export function* sendPushAsync({ payload }) {
  try {
    const firebase = getFirebase()
    const DB = firebase.firestore()
    const profile = yield select(state => state.firebase.profile)
    const shopifyUrl = profile.shopify_url
    const { type, title, message, images, targetType, targetId } = payload

    if (type === "reservation") {
      const { datetime } = payload
      const nextPush = {
        title,
        message,
        type: images ? "image" : "simple",
        target_type: targetType ? targetType : null,
        target_id: targetId ? targetId : null,
        images: images ? images : null,
        time: firebase.firestore.Timestamp.fromDate(new Date(datetime)),
        shop_url: shopifyUrl,
        sent: false,
        valid: true,
        updated_date: firebase.firestore.FieldValue.serverTimestamp(),
        created_date: firebase.firestore.FieldValue.serverTimestamp(),
      }

      const batch = DB.batch()

      // const scheduledPushRef = DB.collection("scheduled_push").doc(id)
      const historyRef = DB.collection(shopifyUrl)
        .doc("push")
        .collection("history")
        .doc()
      const userPushRef = DB.collection("user_list")
        .doc(shopifyUrl)
        .collection("push")
        .doc(historyRef.id)

      // batch.set(scheduledPushRef, nextPush)
      batch.set(historyRef, nextPush)
      batch.set(userPushRef, nextPush)

      batch
        .commit()
        .then(event => {
          track("SEND_SCHEDULED_PUSH")
        })
        .catch(error => {
          window.Sentry.captureException(error)
        })
    }

    if (type === "basic") {
      if (images) {
        const result = yield call(
          callWhereToConnectImagePush,
          shopifyUrl,
          title,
          message,
          targetType,
          targetId,
          images
        )

        if (result && get(result, "status") === 200) {
          yield call(addPushHistory, shopifyUrl, {
            title,
            message,
            type: "image",
            target_type: targetType,
            target_id: targetId,
            images: images,
          })
        }
      } else {
        const result = yield call(
          callWhereToConnectPush,
          shopifyUrl,
          title,
          message
        )

        if (result && get(result, "status") === 200) {
          yield call(addPushHistory, shopifyUrl, {
            title,
            message,
            type: "simple",
            images: null,
          })
        }
      }
    }

    yield put({ type: SEND_PUSH_SUCCESS })

    return "200"
  } catch (error) {
    yield put({ type: SEND_PUSH_FAILURE, payload: error })
  }
}

export function* watchPush() {
  yield takeEvery(SEND_PUSH_REQUEST, sendPushAsync)
}

export default watchPush
