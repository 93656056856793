import { gql } from "@apollo/client"

export const GET_SHOP_NAME = gql`
  query getShopName {
    shop {
      name
    }
  }
`

/**
 * @description 선택한 collection의 products를 포함한 query
 */
export const GET_COLLECTIONS_BY_IDS = gql`
  query getCollectionsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Collection {
        id
        title
        image(maxWidth: 400, maxHeight: 400) {
          transformedSrc(maxWidth: 150, maxHeight: 150)
          src
          originalSrc
        }
        products(first: 4) {
          edges {
            cursor
            node {
              id
              title
              images(first: 1) {
                edges {
                  node {
                    src
                    transformedSrc(maxWidth: 400, maxHeight: 400)
                  }
                }
              }
              variants(first: 1) {
                edges {
                  node {
                    price
                    compareAtPrice
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * @description 선택한 products query
 */
export const GET_PRODUCTS_BY_IDS = gql`
  query getProductsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
        images(first: 1, maxWidth: 400, maxHeight: 400) {
          edges {
            node {
              src
              transformedSrc(maxWidth: 400, maxHeight: 400)
            }
          }
        }
        variants(first: 1) {
          edges {
            node {
              price
              compareAtPrice
            }
          }
        }
      }
    }
  }
`

/**
 * @title Get Product Info By ID For Push
 */
export const GET_PRODUCT_INFO_BY_ID_FOR_PUSH = gql`
  query getProductsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
        images(first: 5, maxWidth: 500, maxHeight: 500) {
          edges {
            node {
              src
              transformedSrc(maxWidth: 150, maxHeight: 150)
            }
          }
        }
      }
    }
  }
`

/**
 * @description collections 검색목록에서 호출
 * 검색에서 transformedSrc load
 *
 * 사용되는 컴포넌트
 * SearchAuto - custom image banner block, slideshow banner, countdown block
 * ShopifySearch - etc
 *
 */
export const COLLECTIONS = gql`
  query Collections($query: String!, $first: Int!, $after: String) {
    collections(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          handle
          title
          image(maxWidth: 400, maxHeight: 400) {
            transformedSrc(maxWidth: 150, maxHeight: 150)
            src
            originalSrc
          }
          products(first: 4) {
            edges {
              cursor
              node {
                id
                title
                images(first: 1) {
                  edges {
                    node {
                      src
                      transformedSrc(maxWidth: 400, maxHeight: 400)
                    }
                  }
                }
                variants(first: 1) {
                  edges {
                    node {
                      price
                      compareAtPrice
                    }
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

/**
 * @description products 검색목록에서 호출
 * 검색에서 transformedSrc load
 *
 * 사용되는 컴포넌트
 * SearchAuto - custom image banner block, slideshow banner, countdown block
 * ShopifySearch - etc
 *
 */
export const PRODUCTS = gql`
  query Products($query: String!, $first: Int!, $after: String) {
    products(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          handle
          title
          images(first: 1, maxWidth: 500, maxHeight: 500) {
            edges {
              node {
                src
                transformedSrc(maxWidth: 150, maxHeight: 150)
              }
            }
          }
          variants(first: 1) {
            edges {
              node {
                price
                compareAtPrice
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const PAGES = gql`
  query Pages($query: String!, $first: Int!, $after: String) {
    pages(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          title
          url
          handle
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
