import { createAction, handleActions } from "redux-actions"
import produce from "immer"

export const AUTHENTICATED = "auth/AUTHENTICATED"
export const LOGIN_REQUEST = "auth/LOGIN_REQUEST"
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS"
export const LOGIN_FAILURE = "auth/LOGIN_FAILURE"
export const LOGIN_WITH_TOKEN_REQUEST = "auth/LOGIN_WITH_TOKEN_REQUEST"

export const authenticated = createAction(AUTHENTICATED)
export const login = createAction(LOGIN_REQUEST)
export const loginWithToken = createAction(LOGIN_WITH_TOKEN_REQUEST)

const initialState = {
  status: "loading",
}

export const authReducer = handleActions(
  {
    [AUTHENTICATED]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = "loading"
      })
    },
    [LOGIN_REQUEST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = "loading"
      })
    },
    [LOGIN_WITH_TOKEN_REQUEST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = "loading"
      })
    },
    [LOGIN_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = "success"
      })
    },
    [LOGIN_FAILURE]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = "failed"
      })
    },
  },
  initialState
)
