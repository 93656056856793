import React from "react"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { PersistGate } from "redux-persist/integration/react"

import { store, persistor, rrfProps } from "./index"

function ReduxProvider({ element }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {element}
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  )
}

export default ReduxProvider
