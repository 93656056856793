import { isUndefined } from "lodash"

const arrayToObject = (array, keyField) => {
  return array
    .filter(o => o !== null)
    .reduce((obj, item) => {
      obj[item[keyField]] = item
      return obj
    }, {})
}

export default arrayToObject
