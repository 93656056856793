import { all } from "redux-saga/effects"

import { watchAuth } from "../auth/sagas"
import { watchShopify } from "../shopify/sagas"
import { watchDesign } from "../design/sagas"
import { watchPush } from "../push/sagas"

function* rootSaga() {
  yield all([watchAuth(), watchShopify(), watchDesign(), watchPush()])
}

export default rootSaga
