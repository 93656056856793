import { createAction, handleActions } from "redux-actions"
import produce from "immer"

export const OPEN_MODAL = "modal/OPEN_MODAL"
export const CLOSE_MODAL = "modal/CLOSE_MODAL"

export const openModal = createAction(OPEN_MODAL)
export const closeModal = createAction(CLOSE_MODAL)

const initialState = {
  type: null,
  meta: null,
  confirm: {},
}

export const modalReducer = handleActions(
  {
    [OPEN_MODAL]: (state, { payload }) => {
      return produce(state, draft => {
        draft.type = payload.type
        draft.meta = payload.meta
      })
    },
    [CLOSE_MODAL]: (state, { payload }) => {
      return produce(state, draft => {
        draft.type = null
        draft.meta = null
      })
    },
  },
  initialState
)
