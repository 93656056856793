import { select } from "redux-saga/effects"

import { buildClient } from "shopify/services"
import { GET_COLLECTIONS_BY_IDS, GET_PRODUCTS_BY_IDS } from "shopify/queries"

export function* fetchCollectionByIds(ids) {
  try {
    const profile = yield select(state => state.firebase.profile)
    const shop = profile.shopify_url
    const token = profile.shopify.sf_access_token
    // const token = profile.access_token

    if (!shop || !token) {
      return false
    }

    const client = buildClient(shop, token)

    const collections = yield client.query({
      query: GET_COLLECTIONS_BY_IDS,
      variables: {
        ids: ids,
      },
    })

    return collections
  } catch (error) {
    console.error(error)
  }
}

export function* fetchProductByIds(ids) {
  try {
    const profile = yield select(state => state.firebase.profile)
    const shop = profile.shopify_url
    const token = profile.shopify.sf_access_token
    // const token = profile.access_token

    if (!shop || !token) {
      return false
    }

    const client = buildClient(shop, token)

    const products = yield client.query({
      query: GET_PRODUCTS_BY_IDS,
      variables: {
        ids: ids,
      },
    })

    return products
  } catch (error) {
    console.error(error)
  }
}

export function* watchShopify() {}

export default watchShopify
