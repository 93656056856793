import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { get } from "lodash"
import { GET_COLLECTIONS_BY_IDS } from "shopify/queries"

export const buildClient = (shopifyUrl, storefrontAccessToken) => {
  const httpLink = createHttpLink({
    uri: `https://${shopifyUrl}/api/2020-07/graphql.json`,
  })
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
      },
    }
  })
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}

export const getCollectionsByIds = async (ids, shop, token) => {
  const client = buildClient(shop, token)

  const request = []

  while (ids.length) {
    request.push(ids.splice(0, 100))
  }

  return new Promise(async (resolve, reject) => {
    try {
      const results = []

      for (const index in request) {
        const collections = await client.query({
          query: GET_COLLECTIONS_BY_IDS,
          variables: {
            ids: request[index],
          },
        })

        if (get(collections, "data.nodes", undefined)) {
          results.push(...collections.data.nodes)
        }
        if (index === request.length - 1) break
        await new Promise(res => setTimeout(res, 1000))
      }
      resolve(results)
    } catch (e) {
      reject(e)
    }
  })
}
