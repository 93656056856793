import { takeEvery, select, put, call, all } from "redux-saga/effects"
import { v4 as uuidv4 } from "uuid"
import {
  assign,
  isEqual,
  isUndefined,
  groupBy,
  sortBy,
  keys,
  some,
  get,
  isNull,
  union,
  concat,
  hasIn,
  omit,
  filter,
  size,
  values,
  isString,
  isEmpty,
  has
} from "lodash"
import produce from "immer"
import moment from "moment"
import store from "store2"
import { getFirebase } from "react-redux-firebase"
import compareVersions from 'compare-versions';
import { rsf } from "../redux"
import {
  UPDATE_UI,
  ADD_BLOCK,
  ADD_BLOCK_SUCCESS,
  UPDATE_BLOCK,
  UPDATE_BLOCK_SUCCESS,
  UPDATE_SECTIONS,
  UPDATE_SECTIONS_SUCCESS,
  SAVE_DESIGN,
  GET_DESIGN,
  GET_DESIGN_SUCCESS,
  GET_DESIGN_FAILRUE,
  SET_SELECTED_SECTION,
  UPDATE_MENU,
  UPDATE_MENU_SUCCESS,
  SAVE_DESIGN_SUCCESS,
  INIT_DESIGN_SUCCESS,
  VALIDATE_BLOCKS,
  VALIDATE_BLOCKS_SUCCESS,
} from "./redux"
import { GET_COLLECTIONS_SUCCESS, GET_PRODUCTS_SUCCESS } from "shopify/redux"
import { fetchCollectionByIds, fetchProductByIds } from "shopify/sagas"
import arrayToObject from "reaverUtil/arrayToObject"
import { track } from "services/analytics"

export function buildBlock(block) {
  if (block.type === "countdown") {
  }

  const data = {
    ...block,
  }

  return data
}

export function* updateBlockAsync({ payload }) {
  try {
    const { id, info } = payload
    let isValid = true

    const block = yield select(state => state.design.blocks.byId[id])

    if (!values(info).some(x => x !== undefined || x !== null)) {
      isValid = false
    }

    if (block.info.type === "countdown") {
      if (isNull(info.image_url)) {
        isValid = true
      }
    }

    if (block.type === "collection_banner") {
      const collectionBannerInfo = get(info, "collection_banner_info", [])

      if (collectionBannerInfo.length === 0) {
        isValid = false
      }
    }

    if (block.type === "collection_list" && has(info ,'collection_ids')) {
      const collectionIds = get(info, "collection_ids", [])

      if (collectionIds.length === 0) {
        isValid = false
      }
    }

    const nextBlock = {
      id,
      info: { ...block.info, ...info },
      isValid,
    }

    if (nextBlock.info.ratio) {
      nextBlock.info.ratio = parseFloat(nextBlock.info.ratio)
    }

    yield put({ type: UPDATE_BLOCK_SUCCESS, payload: nextBlock })
  } catch (error) {
    // console.log(error)
    window.Sentry.captureException(error)
  }
}

export function* addBlockAsync({ payload }) {
  try {
    const { type, order, section } = payload
    let info
    let actionType

    switch (type) {
      case "promocode":
        actionType = "promocode"
        info = {
          // type: "promocode",
          promo_code: undefined,
          promo_title: undefined,
        }
        break
      case "collection_banner":
        actionType = "collection_banner"
        info = {
          // type: "collection_banner",
          collection_banner_info: [],
        }
        break
      case "collection_list":
        actionType = "collection_list"
        info = {
          collection_ids: undefined,
        }
        break
      case "collection_horizontal":
        actionType = "collection"
        info = {
          display_type: "horizontal",
          item_count: 6,
          collection_id: undefined,
        }
        break
      case "collection_vertical":
        actionType = "collection"
        info = {
          display_type: "vertical",
          item_count: 4,
          collection_id: undefined,
        }
        break
      case "product":
        actionType = "product"
        info = {
          product_id: undefined,
          title: undefined,
        }
        break
      case "custom_banner":
        actionType = "custom_banner"
        info = {
          action_type: "",
          action_value: "",
          image_url: "",
        }
        break
      case "collection_carousel":
        actionType = "collection"
        info = {
          collection_id: "",
          display_type: "carousel",
          item_count: 6,
        }
        break
      case "countdown":
        actionType = "countdown"
        info = {}
        break
      case "slideshow":
        actionType = "slideshow"
        info = {}
        break
      default:
        break
    }

    const data = {
      id: uuidv4(),
      order,
      type: actionType,
      section,
      info,
      // info: { promo_code: "page1", promo_title: "page 1 promocode" },
      // info: null,
    }

    yield put({ type: ADD_BLOCK_SUCCESS, payload: data })
  } catch (error) {
    // console.log(error)
    window.Sentry.captureException(error)
  }
}

export function* updateSectionsAsync({ payload }) {
  try {
    const sections = payload
    const previousSectionIds = yield select(state => state.design.sections.ids)
    const sectionIds = sections.ids

    yield all([
      put({ type: SET_SELECTED_SECTION, payload: sections.ids[0] }),
      put({ type: UPDATE_SECTIONS_SUCCESS, payload: sections }),
    ])
  } catch (error) {
    // console.log(error)
    window.Sentry.captureException(error)
  }
}

export function* isValidBlocks() {
  try {
    let isValid = true

    const blocks = yield select(state => state.design.blocks.byId)

    Object.keys(blocks).forEach(blockId => {
      const block = blocks[blockId]

      if (get(block, "isValid", false) === false) {
        isValid = false
      }
    })

    return isValid
  } catch (error) {}
}

export function* backupMenu() {
  try {
    const firebase = getFirebase()
    const DB = firebase.firestore()
    const profile = yield select(state => state.firebase.profile)
    const shopifyUrl = profile.shopify_url

    if (shopifyUrl) {
      const menuV2Snapshot = DB.collection(shopifyUrl).doc("menu_v2")
      const backupRef = DB.collection(shopifyUrl)
        .doc("backup")
        .collection("ux")
        .doc()
    }
  } catch (error) {
    // console.log(error)
    window.Sentry.captureException(error)
  }
}

export function* saveDesignAsync({ payload }) {
  try {
    const firebase = getFirebase()
    const DB = firebase.firestore()
    const design = yield select(state => state.design)
    const profile = yield select(state => state.firebase.profile)
    const ui = design.ui
    const menu = design.menu
    const isMenuActive = design.isMenuActive
    const batch = DB.batch()
    const shopifyUrl = profile.shopify_url
    const isMultipageActive = profile.settings.multipage
    const BLOCK_ENDPOINT = isMultipageActive ? "blocks" : "block"
    const version = get(profile, 'settings.current_version');

    if (isUndefined(shopifyUrl)) {
      return false
    }

    if (isMenuActive) {
      const menuById = menu.byId
      const menuIds = menu.ids
      const menuV2Ref = DB.collection(shopifyUrl)
        .doc("ux")
        .collection("menu_v2")
      const previousMenuSnapshot = yield menuV2Ref.get()
      const backupRef = DB.collection(shopifyUrl)
        .doc("backup")
        .collection("ux")
        .doc(uuidv4())

      batch.set(
        backupRef,
        {
          history: {
            date: firebase.firestore.FieldValue.serverTimestamp(),
          },
        },
        { merge: true }
      )

      previousMenuSnapshot.docs.forEach(doc => {
        if (doc.exists) {
          batch.set(backupRef.collection("menu_v2").doc(doc.id), doc.data(), {
            merge: true,
          })
          batch.delete(menuV2Ref.doc(doc.id))
        }
      })

      menuIds.forEach((menuId, menuIndex) => {
        const menu = menuById[menuId]
        const menuRef = menuV2Ref.doc()

        const nextMenu = {
          ...menu,
          order: menuIndex,
        }

        // 기존웹과의 호환성으로 id 제거
        batch.set(menuRef, omit(nextMenu, ["id"]))
      })
    } else {
      const isValid = yield call(isValidBlocks)

      if (!isValid) {
        alert("Validation failed")
        return false
      }

      const blocksRef = DB.collection(shopifyUrl)
        .doc("ux")
        .collection(BLOCK_ENDPOINT)

      const previousBlocksSnapshot = yield call([blocksRef, "get"])
      const backupRef = DB.collection(shopifyUrl)
        .doc("backup")
        .collection("ux")
        .doc(uuidv4())

      batch.set(
        backupRef,
        {
          history: {
            date: firebase.firestore.FieldValue.serverTimestamp(),
          },
        },
        { merge: true }
      )

      previousBlocksSnapshot.forEach(doc => {
        if (doc.exists) {
          batch.set(
            backupRef.collection(BLOCK_ENDPOINT).doc(doc.id),
            doc.data(),
            {
              merge: true,
            }
          )
          batch.delete(blocksRef.doc(doc.id))
        }
      })

      design.sections.ids.forEach((sectionId, sectionIndex) => {
        const section = design.sections.byId[sectionId]

        section.blocks.forEach((blockId, blockIndex) => {
          const block = design.blocks.byId[blockId]
          const blockRef = blocksRef.doc(blockId)

          let nextInfo = block.info

          const newBlock = {
            type: block.type,
            order: blockIndex,
            info: nextInfo,
          }

          if (isMultipageActive) {
            assign(newBlock, {
              id: blockId,
              section: {
                sectionID: sectionId,
                order: sectionIndex,
                title: section.title,
              },
            })
          }

          batch.set(blockRef, newBlock)
        })
      })

      // DESIGN
      const designRef = DB.collection(shopifyUrl).doc("ux").collection("design")
      const userRef = DB.collection("user_list").doc(shopifyUrl)
      const mobileRef = DB.collection(shopifyUrl).doc("settings")

      batch.set(designRef.doc("accent_color"), {
        color_value: ui.colors.accent,
      })
      batch.set(designRef.doc("primary_color"), {
        color_value: ui.colors.primary,
      })
      batch.set(designRef.doc("button_color"), {
        color_value: ui.colors.button,
      })
      batch.set(designRef.doc("cart_icon"), {
        icon_type: ui.cartIconType,
      })
      batch.set(designRef.doc("brand_logo"), {
        image_url: get(ui, "logo.image_url", ""),
      })
      if(compareVersions.compare(`${version}`, '1.3', '>=')) {
        batch.update(userRef, {
          "settings.image_scale_filled": get(ui, "imageScaleFilled", false),
          "settings.support_portrait": get(ui, "supportPortrait", false),
        })
        batch.update(mobileRef, {
          "mobile_remote_config.image_scale_filled": get(
            ui,
            "imageScaleFilled",
            false
          ),
          "mobile_remote_config.support_portrait": get(ui, "supportPortrait", false),
        })
      }
    }

    yield batch.commit()

    if (!isMenuActive) {
      track("BLOCK_SAVED", {
        count: Object.keys(design.blocks.byId).length,
      })
    }

    // yield firebase.updateProfile({design: {
    //   accent_color:
    // }})
    yield put({ type: SAVE_DESIGN_SUCCESS, payload })
  } catch (error) {
    track("BLOCK_ERROR")
    // console.log(error)
    window.Sentry.captureException(error)
  }
}

export function* getDesignAsync() {
  try {
    const profile = yield select(state => state.firebase.profile)
    const sections = yield select(state => state.design.sections)
    const { shopify_url } = profile
    const shopifyUrl = profile.shopify_url
    const isMultipageActive = get(profile, "settings.multipage", false)
    const BLOCK_ENDPOINT = isMultipageActive ? "blocks" : "block"

    if (!shopify_url) {
      return false
    }

    const [blockSnapshot, designSnapshot, menuV2Snapshot] = yield all([
      call(rsf.firestore.getCollection, `${shopifyUrl}/ux/${BLOCK_ENDPOINT}`),
      call(rsf.firestore.getCollection, `${shopifyUrl}/ux/design`),
      call(rsf.firestore.getCollection, `${shopifyUrl}/ux/menu_v2`),
    ])

    const blocks = {}
    let newSections = {}

    if (isMultipageActive) {
      const multipageSections = {}

      blockSnapshot.forEach(doc => {
        if (doc.exists) {
          const block = doc.data()
          let sectionId = sections.ids[0]

          if (hasIn(block, "section.sectionID")) {
            sectionId = get(block, "section.sectionID", sections.ids[0])
          }

          assign(blocks, {
            [doc.id]: {
              ...block,
              id: doc.id,
              section: sectionId,
              isValid: true,
            },
          })

          if (hasIn(block, "section.sectionID")) {
            assign(multipageSections, {
              [block.section.sectionID]: {
                id: block.section.sectionID,
                order: block.section.order,
                title: block.section.title,
                blocks: [],
              },
            })
          }
        }
      })

      if (Object.keys(multipageSections).length === 0) {
        newSections = {
          [sections.ids[0]]: {
            id: sections.ids[0],
            order: 0,
            title: "Page 1",
            blocks: [],
          },
        }
      } else {
        newSections = multipageSections
      }
    }

    if (!isMultipageActive) {
      blockSnapshot.forEach(doc => {
        if (doc.exists) {
          const block = doc.data()
          const defaultSectionId = sections.ids[0]

          assign(blocks, {
            [doc.id]: {
              ...block,
              section: defaultSectionId,
              id: doc.id,
              isValid: true,
            },
          })
        }
      })

      newSections = {
        [sections.ids[0]]: {
          id: sections.ids[0],
          order: 0,
          title: "Page 1",
          blocks: [],
        },
      }
    }

    const blocksBySection = groupBy(blocks, block => block.section)

    Object.keys(newSections).forEach(sectionId => {
      const blocks = sortBy(blocksBySection[sectionId], "order")

      blocks.forEach(block => {
        newSections[sectionId].blocks.push(block.id)
      })
    })

    let design = {}
    // let menu = {}
    let menuV2 = {}

    designSnapshot.forEach(doc => assign(design, { [doc.id]: doc.data() }))
    // menuSnapshot.forEach(doc => assign(menu, { [doc.id]: doc.data() }))
    menuV2Snapshot.forEach(doc => {
      assign(menuV2, { [doc.id]: { ...doc.data(), id: doc.id } })
    })

    const newSectionIds = sortBy(
      keys(newSections),
      key => newSections[key].order
    )

    // Shopify
    let collectionIds = []
    let productIds = []

    blocks &&
      Object.keys(blocks).length > 0 &&
      Object.keys(blocks).forEach(id => {
        const block = blocks[id]

        switch (block.type) {
          case "collection":
            if (block.info.collection_id) {
              collectionIds = concat(collectionIds, block.info.collection_id)
            }
          case "collection_list":
            if (block.info.collection_ids) {
              collectionIds = concat(collectionIds, block.info.collection_ids)
            }
            break
          case "custom_banner":
          case "countdown":
            if (
              block.info.action_type === "view_collection" &&
              block.info.action_value
            ) {
              collectionIds = concat(collectionIds, block.info.action_value)
            }
            break
          case "collection_banner":
            const collectionBannerIds = block.info.collection_banner_info.map(
              item => item.id
            )
            collectionIds = concat(collectionIds, collectionBannerIds)
            break
          case "product":
            productIds = concat(productIds, block.info.product_id)
            break
          case "slideshow":
            const slideshowIds = block.info.slideshow_info.map(item => {
              if (item.action_type === "view_collection" && item.action_value) {
                if (isString(item.action_value)) {
                  return item.action_value
                }
              }
            })
            collectionIds = concat(collectionIds, slideshowIds)
            break
          default:
            break
        }
      })

    const unionCollections = union(collectionIds)
    const unionProducts = union(productIds)
    const tasks = []

    if (unionCollections.length > 0) {
      tasks.push(call(fetchCollectionByIds, filter(unionCollections, size)))
    }

    if (unionProducts.length > 0) {
      tasks.push(call(fetchProductByIds, filter(unionProducts, size)))
    }

    if (tasks.length === 2) {
        const [collectionResults, productResults] = yield all(tasks)

        yield all([
          collectionResults &&
            put({
              type: GET_COLLECTIONS_SUCCESS,
              payload: arrayToObject(
                filter(collectionResults.data.nodes, size),
                "id"
              ),
            }),
          productResults &&
            put({
              type: GET_PRODUCTS_SUCCESS,
              payload: arrayToObject(
                filter(productResults.data.nodes, size),
                "id"
              ),
            }),
        ])
    } else if (tasks.length === 1) {
      if (unionCollections.length > 0) {
        const [collectionResults] = yield all(tasks)
        yield all([
          collectionResults &&
            put({
              type: GET_COLLECTIONS_SUCCESS,
              payload: arrayToObject(
                filter(collectionResults.data.nodes, size),
                "id"
              ),
            })
        ])
      } else if (unionProducts.length > 0) {
        const [productResults] = yield all(tasks)
        yield all([
          productResults &&
            put({
              type: GET_PRODUCTS_SUCCESS,
              payload: arrayToObject(
                filter(productResults.data.nodes, size),
                "id"
              ),
            }),
        ])
      }
    }
    // End Shopify

    yield all([
      put({
        type: UPDATE_UI,
        payload: {
          colors: {
            primary: get(design, "primary_color.color_value", "#ffffff"),
            accent: get(design, "accent_color.color_value", "#d20358"),
            button: get(design, "button_color.color_value", "#d20459"),
          },
          cartIconType: design.cart_icon.icon_type,
          logo: get(design, "brand_logo", ""),
          imageScaleFilled: get(profile, "settings.image_scale_filled", false),
          supportPortrait: get(profile, "settings.support_portrait", false),
        },
      }),
      put({
        type: GET_DESIGN_SUCCESS,
        payload: {
          menu: {
            byId: menuV2,
            ids: sortBy(keys(menuV2), key => menuV2[key].order),
          },
          selectedSection:
            newSectionIds.length > 0 ? newSectionIds[0] : sections.ids[0],
          blocks: {
            byId: blocks,
            ids: Object.keys(blocks),
          },
          sections: {
            byId: newSections,
            ids: sortBy(keys(newSections), key => newSections[key].order),
          },
        },
      }),
    ])
  } catch (error) {
    yield put({ type: GET_DESIGN_FAILRUE, payload: error })
  }
}

export function* storeDesignAsync() {
  try {
    const design = yield select(state => state.design)
    const menuById = design.menu.byId
    const menuIds = design.menu.ids
    const sectionById = design.sections.byId
    const sectionIds = design.sections.ids
    const blockById = design.blocks.byId
    const blockIds = design.blocks.ids
    const ui = design.ui

    store.session.remove("design")
    store.session("design", {
      menuById,
      menuIds,
      sectionById,
      sectionIds,
      blockById,
      blockIds,
      ui,
    })

    yield put({ type: INIT_DESIGN_SUCCESS })
  } catch (error) {}
}

export function* validateBlocksAsync() {
  try {
    const blocks = yield select(state => state.design.blocks.byId)
    const inValidBlocks = {}

    Object.keys(blocks).forEach(blockId => {
      if (blocks[blockId].isValid === false) {
        assign(inValidBlocks, { [blockId]: blocks[blockId] })
      }
    })

    if (Object.keys(inValidBlocks).length > 0) {
      yield put({
        type: VALIDATE_BLOCKS_SUCCESS,
        payload: Object.keys(inValidBlocks),
      })
    }
  } catch (error) {
    // console.log(error)
    window.Sentry.captureException(error)
  }
}

export function* watchDesign() {
  yield takeEvery(VALIDATE_BLOCKS, validateBlocksAsync)
  yield takeEvery(ADD_BLOCK, addBlockAsync)
  yield takeEvery(UPDATE_BLOCK, updateBlockAsync)
  yield takeEvery(UPDATE_SECTIONS, updateSectionsAsync)
  yield takeEvery(SAVE_DESIGN, saveDesignAsync)
  yield takeEvery(GET_DESIGN, getDesignAsync)
  yield takeEvery(GET_DESIGN_SUCCESS, storeDesignAsync)
  yield takeEvery(SAVE_DESIGN_SUCCESS, storeDesignAsync)
}

export default watchDesign
