import fbq from "gatsby-plugin-facebook-pixel"
import { get } from "lodash"
import { store } from "../redux/index"

export const getPrefixByPlatfrom = platform => {
  let prefix = ""

  switch (platform) {
    case "android":
      prefix = "-a"
      break
    case "ios":
      prefix = "-i"
      break
    case "all":
      prefix = ""
      break
    default:
      console.warn("unexpected platform! " + platform)
  }

  return prefix
}
export const getPrefixByPlan = plan => {
  let prefix = ""

  switch (plan) {
    case "starter":
      prefix = "s"
      break
    case "starter_yearly":
      prefix = "s-y"
      break
    case "growth":
      prefix = "g"
      break
    case "growth_yearly":
      prefix = "g-y"
      break
    case "power":
      prefix = "p"
      break
    case "power_yearly":
      prefix = "p-y"
      break
    case "platinum":
      prefix = "t"
      break
    case "platinum_yearly":
      prefix = "t-y"
      break
    default:
      console.warn("unexpected plan! " + plan)
  }

  return prefix
}

export const renderIntercomName = ({
  first_name,
  last_name,
  shopify_url,
  payment,
}) => {
  const paid = get(payment, "paid", false)
  const plan = get(payment, "plan", undefined)
  const plaform = get(payment, "platform", undefined)

  if (paid) {
    if (plan) {
      const planPrefix = getPrefixByPlan(plan)
      const platformPrefix = getPrefixByPlatfrom(plaform)

      return `[${planPrefix}${platformPrefix}@${shopify_url.replace(
        ".myshopify.com",
        ""
      )}] ${first_name} ${last_name}`
    }
  }

  return `[${shopify_url.replace(
    ".myshopify.com",
    ""
  )}] ${first_name} ${last_name}`
}

export const fbTrack = (name, meta) => {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq(name, meta)
    // trackCustomEvent(name, meta)
  }
}

export const intercomTrack = (type, data) => {
  const { firebase } = store.getState()
  const shopify_url = get(firebase, "profile.shopify_url", undefined)

  const meta = {
    ...data,
    shopify_url,
  }

  window.Intercom("trackEvent", type, meta)
}

export const gaTrack = (category, action, label, type) => {

  // gatsby-plugin-google-analytics 사용할때 comment해제하고 사용  
  // trackCustomEvent({
  //   category: category,
  //   action: action,
  //   label: label,
  //   type: type,        
  // })

  //gatsby-plugin-google-gtag 사용할때 comment해제하고 사용 
  // if (process.env.NODE_ENV === "production") {
  // typeof window !== "undefined" && window.gtag('event', action, {
  //   'event_category': category,
  //   'event_label': label,
  // });
  // }
}

export const track = (type, data) => {
  switch (type) {
    case "BLOCK_SAVED":
      intercomTrack("block_saved", data)
      gaTrack("AddToCart","save",`${data.count}`,"event")
      break
    case "BLOCK_ERROR":
      intercomTrack("block_error", data)
      break
    case "INITIATE_CHECKOUT":
      intercomTrack("InitiateCheckout", data)
      break
    case "SUBSCRIBED":
      intercomTrack("subscribed", data)
      break
    case "UPGRADED":
      intercomTrack("upgraded", data)
      break
    case "VIEW_POPUP_THANKYOU":
      intercomTrack("view_thank_you_popup", data)
      break
    case "SEND_PUSH":
      intercomTrack("send_push", data)
      break
    case "SEND_IMAGE_PUSH":
      intercomTrack("send_image_push", data)
      break
    case "SEND_SCHEDULED_PUSH":
      intercomTrack("send_scheduled_push", data)
      break
    case "VIEW_PAGE_SLIDING":
      intercomTrack("view_sliding", data)
      break
    case "VIEW_POPUP_UPGRADE":
      intercomTrack("view_upgrade_popup", data)
      break
    case "VIEW_PAGE_APPINFO":
      intercomTrack("view_appinfo", data)
      break
    case "VIEW_PAGE_ACCOUNT_SELECTION":
      intercomTrack("view_account_selection", data)
      break
    case "VIEW_PAGE_APPSTATUS":
      intercomTrack("view_appstatus", data)
      break
    case "VIEW_PAGE":
      var page_name = data.pathname.replace("/app/", "")
      intercomTrack(`view_page_${page_name}`, data)
      gaTrack("ViewContent","view",page_name,"event")
      break
    case "VIEW_INTEGRATION_APPPAGE":
        var appname = data.appname;
        intercomTrack(`checked_${appname}`)
      break
    case "CONNECT_INTEGRATION_APP":
        var appname = data.appname;
        intercomTrack(`connected_${appname}`,data)
    break
    case "DISCONNECT_INTEGRATION_APP":
        var appname = data.appname;
        intercomTrack(`disconnected_${appname}`,data)
    break
    case "UPDTE_INTEGRATION_APP":
      var appname = data.appname;
      intercomTrack(`updated_${appname}`,data)
    break
    case "EDIT_MENU":
      intercomTrack('edit_menu',data)
  }
}