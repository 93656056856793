import { createAction, handleActions } from "redux-actions"
import produce from "immer"

export const OPEN_DIALOG = "dialog/OPEN_DIALOG"
export const CLOSE_DIALOG = "dialog/CLOSE_DIALOG"

export const openDialog = createAction(OPEN_DIALOG)
export const closeDialog = createAction(CLOSE_DIALOG)

const initialState = {
  type: null,
  modalProps: null,
}

export const dialogReducer = handleActions(
  {
    [OPEN_DIALOG]: (state, { payload }) => {
      return produce(state, draft => {
        draft.type = payload.type
        draft.modalProps = payload.modalProps
      })
    },
    [CLOSE_DIALOG]: (state, { payload }) => {
      return produce(state, draft => {
        draft.type = null
        draft.modalProps = null
      })
    },
  },
  initialState
)
