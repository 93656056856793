import { createAction, handleActions } from "redux-actions"
import { includes, get, isUndefined } from "lodash"
import produce from "immer"

export const ADD_BLOCK = "design/ADD_BLOCK"
export const ADD_BLOCK_SUCCESS = "design/ADD_BLOCK_SUCCESS"
export const ADD_BLOCK_FAILURE = "design/ADD_BLOCK_FAILURE"
export const UPDATE_BLOCK = "design/UPDATE_BLOCK"
export const UPDATE_BLOCK_SUCCESS = "design/UPDATE_BLOCK_SUCCESS"
export const REMOVE_BLOCK = "design/REMOVE_BLOCK"
export const SWAP_BLOCK = "design/SWAP_BLOCK"
export const GET_DESIGN = "design/GET_DESIGN"
export const GET_DESIGN_SUCCESS = "design/GET_DESIGN_SUCCESS"
export const GET_DESIGN_FAILRUE = "design/GET_DESIGN_FAILRUE"
export const UPDATE_UI = "design/UPDATE_UI"
export const UPDATE_COLORS = "design/UPDATE_COLORS"
export const UPDATE_SECTIONS = "design/UPDATE_SECTIONS"
export const UPDATE_SECTIONS_SUCCESS = "design/UPDATE_SECTIONS_SUCCESS"
export const UPDATE_SECTIONS_FAILURE = "design/UPDATE_SECTIONS_FAILURE"
export const SET_SELECTED_SECTION = "design/SET_SELECTED_SECTION"
export const SET_CART_ICON_TYPE = "design/SET_CART_ICON_TYPE"
export const SAVE_DESIGN = "design/SAVE_DESIGN"
export const SAVE_DESIGN_SUCCESS = "design/SAVE_DESIGN_SUCCESS"
export const SAVE_DESIGN_FAILURE = "design/SAVE_DESIGN_FAILURE"
export const VALIDATE_BLOCKS = "design/VALIDATE_BLOCKS"
export const VALIDATE_BLOCKS_SUCCESS = "design/VALIDATE_BLOCKS_SUCCESS"
export const SET_BLOCK_VALID = "design/SET_BLOCK_VALID"

export const ADD_MENU = "design/ADD_MENU"
export const ADD_MENU_SUCCESS = "design/ADD_MENU_SUCCESS"
export const ADD_MENU_FAILURE = "design/ADD_MENU_FAILURE"
export const REMOVE_MENU = "design/REMOVE_MENU"
export const REMOVE_MENU_SUCCESS = "design/REMOVE_MENU_SUCCESS"
export const REMOVE_MENU_FAILURE = "design/REMOVE_MENU_FAILURE"
export const EDIT_MENU = "design/EDIT_MENU"
export const EDIT_MENU_SUCCESS = "design/EDIT_MENU_SUCCESS"
export const EDIT_MENU_FAILURE = "design/EDIT_MENU_FAILURE"
export const SWAP_MENU = "design/SWAP_MENU"
export const TOGGLE_MENU = "design/TOGGLE_MENU"
export const UPDATE_MENU = "design/UPDATE_MENU"
export const UPDATE_MENU_SUCCESS = "design/UPDATE_MENU_SUCCESS"
export const DESIGN_CHANGED = "design/DESIGN_CHANGED"
export const INIT_DESIGN_SUCCESS = "design/INIT_DESIGN_SUCCESS"
export const SET_DESIGN_STATUS = "design/SET_DESIGN_STATUS"

export const getDesign = createAction(GET_DESIGN)
export const saveDesign = createAction(SAVE_DESIGN)
export const changedDesign = createAction(DESIGN_CHANGED)
export const initDesignSuccess = createAction(INIT_DESIGN_SUCCESS)
export const setDesignStatus = createAction(SET_DESIGN_STATUS)
export const setBlockValid = createAction(SET_BLOCK_VALID)

export const addBlock = createAction(ADD_BLOCK)
export const updateBlock = createAction(UPDATE_BLOCK)
export const removeBlock = createAction(REMOVE_BLOCK)
export const swapBlock = createAction(SWAP_BLOCK)
export const validateBlocks = createAction(VALIDATE_BLOCKS)

export const addMenu = createAction(ADD_MENU)
export const removeMenu = createAction(REMOVE_MENU)
export const updateMenu = createAction(UPDATE_MENU_SUCCESS)
export const swapMenu = createAction(SWAP_MENU)

export const updateSections = createAction(UPDATE_SECTIONS)
export const setSelectedSection = createAction(SET_SELECTED_SECTION)
export const setCartIconType = createAction(SET_CART_ICON_TYPE)
export const updateColors = createAction(UPDATE_COLORS)
export const updateUI = createAction(UPDATE_UI)
export const toggleMenu = createAction(TOGGLE_MENU)

const initialState = {
  blocks: {
    byId: {},
    ids: [],
    isEmpty: true,
    isLoaded: false,
  },
  sections: {
    byId: {
      default: {
        id: "default",
        title: "Page 1",
        blocks: [],
      },
    },
    ids: ["default"],
  },
  menu: {
    byId: {
      home: {
        title: "Home",
      },
    },
    ids: ["home"],
  },
  ui: {
    colors: {
      primary: "#ffffff",
      accent: "#d20358",
      button: "#d20459",
    },
    logo: "",
    cartIconType: "bag",
    theme: "urban",
    supportPortrait: false,
    imageScaleFilled: false,
  },
  multipage: true,
  selectedSection: "default",
  isMenuActive: false,
  isDesignChanged: false,
  isMenuChanged: false,
  initailized: false,
  status: null,
  afterAction: null,
}

export const designReducer = handleActions(
  {
    [INIT_DESIGN_SUCCESS]: state => {
      return produce(state, draft => {
        draft.initailized = true
      })
    },
    [SAVE_DESIGN]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = "saving"
        draft.afterAction = get(payload, "afterAction", null)
      })
    },
    [SAVE_DESIGN_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.isDesignChanged = false
        draft.isMenuChanged = false
        draft.status = "saved"
        draft.afterAction = get(payload, "afterAction", null)
      })
    },
    [SET_DESIGN_STATUS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.status = payload
        draft.afterAction = null
      })
    },
    [DESIGN_CHANGED]: (state, { payload }) => {
      if (payload.type === "design") {
        return produce(state, draft => {
          draft.isDesignChanged = payload.value
        })
      }

      if (payload.type === "menu") {
        return produce(state, draft => {
          draft.isMenuChanged = payload.value
        })
      }
    },
    [UPDATE_MENU_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.menu.byId[payload.id] = payload

        if (!includes(state.menu.ids, payload.id)) {
          draft.menu.ids.push(payload.id)
        }
      })
    },
    [GET_DESIGN]: state => {
      return produce(state, draft => {
        draft.blocks.isLoaded = true
        draft.blocks.isEmpty = false
        draft.initailized = false
        draft.status = "loading"
      })
    },
    [GET_DESIGN_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.blocks.isLoaded = false
        draft.blocks.isEmpty = true
        draft.blocks.byId = payload.blocks.byId
        draft.blocks.ids = payload.blocks.ids
        draft.menu.byId = payload.menu.byId
        draft.menu.ids = payload.menu.ids
        draft.sections.byId = payload.sections.byId
        draft.sections.ids = payload.sections.ids
        draft.selectedSection = payload.selectedSection
        draft.status = "loaded"
        // draft.initailized = true
      })
    },
    [VALIDATE_BLOCKS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        payload.forEach(blockId => {
          draft.blocks.byId[blockId].error = true
        })
      })
    },
    [UPDATE_UI]: (state, { payload }) => {
      return produce(state, draft => {
        draft.ui = { ...state.ui, ...payload }
      })
    },
    [SET_BLOCK_VALID]: (state, { payload }) => {
      return produce(state, draft => {
        const { id, isValid } = payload
        draft.blocks.byId[id].isValid = isValid
      })
    },
    [ADD_BLOCK_SUCCESS]: (state, { payload }) => {
      console.log("ADD BLOCK", payload, state.sections.byId)
      return produce(state, draft => {
        const { id, order, type, section, info } = payload
        let blockData = {
          id,
          type,
          section,
          order,
          info,
          isValid: false,
        }

        const selectedSection = get(
          state,
          `sections.byId.${blockData.section}.blocks`,
          undefined
        )

        if (!selectedSection) {
          blockData.section = "default"
          draft.sections.byId["default"] = {
            id: "default",
            title: "Page 1",
            blocks: [],
          }
          !state.sections.ids.includes("default") &&
            draft.sections.ids.push("default")
          draft.selectedSection = "default"
        }
        draft.blocks.byId[id] = blockData

        isUndefined(selectedSection) || selectedSection.length === 0
          ? draft.sections.byId[blockData.section].blocks.push(id)
          : draft.sections.byId[blockData.section].blocks.splice(order, 0, id)
      })
    },
    [REMOVE_BLOCK]: (state, { payload }) => {
      return produce(state, draft => {
        const { id } = payload
        const sectionId = state.blocks.byId[id].section

        delete draft.blocks.byId[id]

        const blockIds = draft.blocks.ids
        const sectionBlocks = draft.sections.byId[sectionId].blocks

        const blockIdsIndex = blockIds.findIndex(blockId => blockId === id)
        if (blockIdsIndex !== -1) blockIds.splice(blockIdsIndex, 1)

        const sectionBlocksIndex = sectionBlocks.findIndex(
          blockId => blockId === id
        )
        if (sectionBlocksIndex !== -1)
          sectionBlocks.splice(sectionBlocksIndex, 1)
      })
    },
    [SWAP_BLOCK]: (state, { payload }) => {
      return produce(state, draft => {
        const { from, to, section } = payload

        if (to < 0) return draft.sections.byId[section].blocks

        const dragged = state.sections.byId[section].blocks[from]

        draft.sections.byId[section].blocks.splice(from, 1)
        draft.sections.byId[section].blocks.splice(to, 0, dragged)
      })
    },
    [UPDATE_SECTIONS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.sections.byId = payload.byId
        draft.sections.ids = payload.ids
      })
    },
    [SET_SELECTED_SECTION]: (state, { payload }) => {
      return produce(state, draft => {
        draft.selectedSection = payload
      })
    },
    [UPDATE_BLOCK_SUCCESS]: (state, { payload }) => {
      const { id, info, isValid } = payload
      return produce(state, draft => {
        draft.blocks.byId[id].isValid = isValid
        draft.blocks.byId[id].error = false
        draft.blocks.byId[id].info = info
      })
    },
    [UPDATE_SECTIONS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.sections.byId = payload.byId
        draft.sections.ids = payload.ids
      })
    },
    [UPDATE_COLORS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.ui.colors = { ...state.ui.colors, ...payload }
      })
    },
    [SET_CART_ICON_TYPE]: (state, { payload }) => {
      return produce(state, draft => {
        draft.ui.cartIconType = payload
      })
    },
    [TOGGLE_MENU]: (state, { payload }) => {
      return produce(state, draft => {
        draft.isMenuActive = !state.isMenuActive
      })
    },
    [ADD_MENU_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        const { id, order, type, section, info } = payload
        const blockData = {
          id,
          type,
          section,
          order,
          info,
          isValid: false,
        }

        draft.blocks.byId[id] = blockData

        const sectionBlocks = draft.sections.byId[section].blocks

        sectionBlocks.length === 0 && sectionBlocks.push(id)
        sectionBlocks.splice(order, 0, id)
      })
    },
    [REMOVE_MENU]: (state, { payload }) => {
      return produce(state, draft => {
        const { id } = payload
        delete draft.menu.byId[id]

        const index = draft.menu.ids.findIndex(menuId => menuId === id)
        if (index !== -1) draft.menu.ids.splice(index, 1)
      })
    },
    [SWAP_MENU]: (state, { payload }) => {
      return produce(state, draft => {
        const { from, to } = payload

        if (to < 0) return draft.menu.ids

        const dragged = state.menu.ids[from]

        draft.menu.ids.splice(from, 1)
        draft.menu.ids.splice(to, 0, dragged)
      })
    },
  },
  initialState
)
