import { createAction, handleActions } from "redux-actions"
import produce from "immer"

export const GET_COLLECTIONS = "shopify/GET_COLLECTIONS"
export const GET_COLLECTIONS_SUCCESS = "shopify/GET_COLLECTIONS_SUCCESS"
export const GET_COLLECTION_SUCCESS = "shopify/GET_COLLECTION_SUCCESS"
export const GET_PRODUCTS_SUCCESS = "shopify/GET_PRODUCTS_SUCCESS"
export const UPDATE_COLLECTIONS_SUCCESS = "shopify/UPDATE_COLLECTIONS_SUCCESS"
export const UPDATE_PRODUCTS_SUCCESS = "shopify/UPDATE_PRODUCTS_SUCCESS"

export const getCollectionByids = createAction(GET_COLLECTIONS)
export const getCollectionSuccess = createAction(GET_COLLECTION_SUCCESS)
export const updateCollectionsSuccess = createAction(UPDATE_COLLECTIONS_SUCCESS)
export const updateProductsSuccess = createAction(UPDATE_PRODUCTS_SUCCESS)

const initialState = {
  collections: {
    byId: {},
    ids: [],
  },
  products: {
    byId: {},
    ids: [],
  },
}

export const shopifyReducer = handleActions(
  {
    [GET_COLLECTION_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.collections.byId[payload.id] = payload
        draft.collections.ids.push(payload.id)
      })
    },
    [UPDATE_COLLECTIONS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        Object.keys(payload).forEach(id => {
          draft.collections.byId[id] = payload[id]
          draft.collections.ids.push(id)
        })
      })
    },
    [UPDATE_PRODUCTS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        Object.keys(payload).forEach(id => {
          draft.products.byId[id] = payload[id]
          draft.products.ids.push(id)
        })
      })
    },
    [GET_COLLECTIONS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.collections.byId = payload
        draft.collections.ids = Object.keys(payload)
      })
    },
    [GET_PRODUCTS_SUCCESS]: (state, { payload }) => {
      return produce(state, draft => {
        draft.products.byId = payload
        draft.products.ids = Object.keys(payload)
      })
    },
  },
  initialState
)
